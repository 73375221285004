<style lang="scss">
$width800: 800px;
.native {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
    .content-link-modal {
      -webkit-transition: all 500ms ease;
      cursor: pointer;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      display: block;
      text-align: center;
      padding: 10px;
      background: $blue;
      color: #fff;
      margin-bottom: 45px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .vorteile {
    margin: 20px 0;
    text-align: center;
    .nr {
      margin-bottom: 10px;
      text-align: center;
      display: block;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 0;
        color: $blue-darker;
        font-size: 60px;
        z-index: 1;
        left: 50%;
        margin-left: -29px;
        top: 23px;
        @media (max-width: $width-lg) {
        }
      }
      @media (max-width: $width-lg) {
        display: block;
      }
      span {
        display: inline-block;
        color: #fff;
        position: relative;
        z-index: 2;
        line-height: 50px;
        width: 50px;
        font-size: 100%;
      }
    }
    h5 {
      text-align: center;
      display: inline-block;
      font-weight: 300;
      padding: 0 15px;
      @media (max-width: $width-md) {
        margin-bottom: 20px;
      }
      @media (max-width: 600px) {
        display: block;
        margin-left: 0;
        text-align: center;
      }
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .native-ads-block {
    .col-md-4 {
      padding: 2%;
      .native-ad-numbers {
        text-align: center;
        @media (max-width: 768px) {
          margin-bottom: 50px;
          border: 0px solid blue;
        }
        span {
          font-size: 2em;
          font-family: $KleineTitel-Bold;
          background: $blue-darker;
          color: #fff;
          padding: 20px 0px;
          max-width: 200px;
          display: block;
          margin: 0 auto;
        }
        .headline-keywords {
          font-size: 1.5em;
          text-transform: uppercase;
          color: $blue-darker;
          font-family: $KleineTitel-Bold;
          margin-bottom: 20px;
          // font-family: $font-family-roboto-slap;
        }
        p {
          text-align: center;
          line-height: 1.8;
        }
        .native-ad-img {
          height: 180px;
          margin-bottom: 30px;
          position: relative;
          transform: scale(1);
          overflow: hidden;
          transition: transform 125ms linear;
          &:after {
            content: '';
            display: block;
            width: 50px;
            height: 5px;
            background: #ddd;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            margin-left: -25px;
            bottom: 0;
            display: none;
          }
          img {
            display: block;
            margin: 20px auto;
            width: 100px;
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
        &:hover {
          .native-ad-img {
            // transform: scale(1.1);
          }
        }
      }
    }
  }
}
.info-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 120%;
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.btn-big {
  display: block;
  margin: 10px 0;
  text-align: center;
  margin-top: 30px;
  a {
    display: inline-block;
    padding: 15px 20px;
    background: $blue;
    color: #fff !important;
    &:hover {
      opacity: 0.9;
    }
  }
}

.erfolgbsp {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;

    position: relative;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    .small {
      @media (max-width: 1370px) and (min-width: 1200px) {
        display: block;
      }
    }
    @media (max-width: 768px) {
      display: block;
      width: 100%;

      text-align: center;
    }
  }
  .inner-content {
    .headline {
      margin-top: 40px;
    }
    .vorteile {
      margin-bottom: 40px;
      .col-lg-4 {
        justify-content: flex-start !important;
        align-self: flex-start !important;
      }
    }
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $blue-darker;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $blue-darker;
      }
    }
  }
  .breaker {
    display: block;
    height: 1px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .highlight-txt {
    background: $blue;
    padding: 15px;
    color: #fff;
    a {
      color: $blue-darker !important;
    }
  }
}
</style>

<template>
  <HighlightBox />
  <div class="content native container">
    <h1>Erzählen Sie Ihre Geschichte!</h1>
    <p class="intro-txt">
      Unsere Expertinnen und Experten in Sachen Content verpacken Ihre Werbebotschaften im Stil redaktioneller Berichterstattung - beratend, informativ und unterhaltsam aufbereitet. Diese „natürlich“ anmutenden, aber werblich gekennzeichneten Inhalte bieten für Leserinnen und Leser einen echten Mehrwert. Sie wirken auf der emotionalen Ebene und bleiben dadurch länger im Gedächtnis.
    </p>
    <p>Vom maßgeschneiderten PR-Text für Ihr Unternehmen, Angebot oder Produkt bis hin zum professionellen Storytelling in Form einer Sponsored Story haben wir für Ihren persönlichen Werbebedarf die richtige Lösung: <strong>glaubwürdig, nachhaltig, wirksam</strong>.</p>

    <div class="margin-t-s">
      <h2>Ihre Werbemöglichkeiten im Bereich Native</h2>
    </div>
    <TabGroup>
      <Tab tabid="prpdodukt-tab1" :title="'Sponsored Story'" active>
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Sponsored Story</h2>
            </div>
            <div class="col-lg-12 align-self-center">
              <p>
                Sponsored Stories sind redaktionelle Artikel mit Kundenintegration. Die Inhalte sind authentisch geschrieben und als Werbung gekennzeichnet. Anders als bei Advertorials steht das Thema und nicht das Unternehmen / Produkt / Angebot im Vordergrund. Werbekunden werden als Experten zu einem bestimmten Thema positioniert. Die Story wird über mehrere Kanäle u.a. kleinezeitung.at, App und
                Facebook Post ausgespielt.
              </p>
            </div>
          </div>
          <!--<div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>lange Verweildauer durch Storytelling</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>aktivierend und glaubwürdig</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>bleibt lange in Erinnerung und <span class="breaker"></span> wirkt nachhaltig</h5>
            </div>
          </div>
          -->
          <div class="row native-ads-block justify-content-center padding-tb-s">
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">84%</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-herz.svg" alt="Glaubwürdig" class="img-fluid" />
                </div>
                <div class="headline-keywords">Glaubwürdig</div>
                <p>84 Prozent der Leser empfinden die gelesenen Inhalte als stimmig und vertrauensvoll.</p>
              </div>
            </div>
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">4/5</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-nachhaltig.svg" alt="Nachhaltig" class="img-fluid" />
                </div>
                <div class="headline-keywords">Nachhaltig</div>
                <p>Mehr als 4/5 der Leser erinnern sich an mindestens ein Detail des Ads.</p>
              </div>
            </div>
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">2:30</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-wirksam.svg" alt="Wirksam" class="img-fluid" />
                </div>
                <div class="headline-keywords">Wirksam</div>
                <p>Im Durchschnitt setzen sie sich mit dem Inhalt rund 2:30 Minuten auseinander.</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Ausführliches Briefing und Projektmanagement</li>
                <li>Content Produktion im redaktionellen Look &amp; Feel <small>(zwei Korrekturschleifen inklusive)</small></li>
                <li>Bewerbung auf kleinezeitung.at, auf der Kleinen Zeitung-APP und in den sozialen Netzwerken</li>
                <li>Reporting der relevanten Kennzahlen</li>
                <li>Inkl. einmalige Integration im Kleine Zeitung-Mittags-Newsletter (für Stmk und Ktn)</li>
                <li>Vorlaufzeit: 5 Werktage</li>
              </ul>
              <br />

              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 Leserinnen und Lesern</div>
                  <div class="col-lg-6 col-4 text-align-right">4.985,00</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">1.500 Leserinnen und Lesern</div>
                  <div class="col-lg-6 col-4 text-align-right">3.450,00</div>
                </div>
              </div>

              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/smartphone-sponsored-story.png" alt="Sponsored Story" class="img-fluid img-s-60 img-center" />
            </div>
          </div>

          <div class="info-txt-preis margin-b-s">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
        </div>

        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/digital/Kleine-Zeitung-Sponsored-Story.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Kleine Zeitung Sponsored-Story
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>211 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab2" :title="'Image-Story'">
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Image-Story</h2>
              <h3>Nachhaltige Verankerung und Stärkung von Marke und Unternehmen</h3>
            </div>
            <div class="col-lg-12 align-self-center">
              <p>
                Nachhaltig die Marke und das Unternehmen in den Köpfen zu verankern – das ist das Spezialgebiet der Image-Story. Das Storytelling mit vielfältigen Erzählformaten (Interview, Reportage, Kommentar, Erlebnisbericht) spricht vor allem die emotionale Ebene an. So bleibt Ihre Werbebotschaft länger und nachhaltig im Gedächtnis Ihrer Kunden.
              </p>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>Leistungen &amp; Tarif</h2>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="margin-t-s"></div>
              <h2>BASISMODULE</h2>
              <ul class="content-ul">
                <li><strong>Erstellung der Basisstory</strong><br />Content-Profis der Kleinen Zeitung erstellen und gestalten mit Ihnen eine ansprechende Story für genau Ihre Zielgruppe und ermöglichen einen exakten Überblick über Ihre Storyviews.</li>
                <li><strong>Infobox / Disclaimerbox / Outlink</strong><br />Mithilfe einer Infobox / Disclaimerbox / Outlink werden potenzielle Kunden bestmöglich informiert und direkt auf Ihre Website weitergeleitet.</li>
                <li>
                  <strong>Video</strong><br />
                  Ihr bereitgestelltes Video wird in die Image-Story integriert und sorgt für emotionale Aufmerksamkeit
                </li>
                <li>
                  <strong>Zitat</strong><br />
                  Mithilfe eines ausgewählten Zitates unterstreichen Sie Ihre Aussagen und stärken gleichzeitig Ihre Glaubwürdigkeit.
                </li>
                <li>
                  <strong>Bildergalerie</strong><br />
                  Bleiben Sie langfristig in Erinnerung – die Bildergalerie bietet Ihren Kunden einen visuellen Überblick über Ihre Dienstleistungen oder Produkte.
                </li>
                <li>
                  <strong>Instagram-Story</strong><br />
                  Durch die Instagram-Story ist Ihnen ein reichweitenstarker Auftritt gesichert.
                </li>
                <li>
                  <strong>Social-Share-Buttons</strong><br />
                  Das Teilen Ihrer Story mit Freunden und Familie ist unkompliziert mithilfe diverser Social-Share-Buttons möglich.
                </li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 garantierte Leserinnen und Leser</div>
                  <div class="col-lg-6 col-4 text-align-right">5.140,00</div>
                </div>
              </div>
              <img src="@/assets/img/img-content/digital/Image-Story_iMac.png" alt="Image Story" class="img-fluid  img-center" />
              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/6128590" target="_blank">Siehe Beispiel </a>
              </div>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>ADD-ON-MODULE</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Videoproduktion</strong><br />Wecken Sie Emotionen durch ein ausdrucksstarkes Unternehmensvideo, welches wir für Sie je nach gewünschtem Leistungsumfang produzieren.</li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Videoproduktion</div>
                  <div class="col-lg-6 col-4 text-align-right">1.560,00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Kurzinterview</strong><br />Schaffen Sie Vertrauen und Transparenz bei potenziellen Kunden mithilfe eines authentischen Kurzinterviews.</li>
              </ul>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Kurzinterview</div>
                  <div class="col-lg-6 col-4 text-align-right">110,00</div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Podcast (verfügbar ab Q3/2022)</strong><br />Wirksam Ihre Zielgruppe zu erreichen geht auch anders – Ihr spannender Podcast begleitet unsere Leserinnen und Leser durch den Alltag.</li>
              </ul>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Podcast</div>
                  <div class="col-lg-6 col-4 text-align-right">auf Anfrage</div>
                </div>
              </div>
            </div>
          </div>
          -->

          <!-- -->
          <div class="download-pdf">
            <div class="line"><span>DOWNLOAD</span></div>
            <a href="../../pdf/digital/Kleine-Zeitung-Image-Story.pdf" target="_blank">
              <div class="row">
                <div class="col-8 justify-content-center align-self-center">
                  Kleine Zeitung - Image Story
                </div>
                <div class="col-4 justify-content-end align-self-center text-right">
                  <span>289 kb // PDF</span>
                </div>
              </div>
            </a>
          </div>
          <!---->
          <div class="info-txt-preis margin-b-s">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab3" :title="'Blogging-Story'">
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Blogging-Story</h2>
              <h3>Produktwissen unterhaltsam und lehrreich vermitteln</h3>
            </div>
            <div class="col-lg-12 align-self-center">
              <p>
                Produktwissen unterhaltsam und lehrreich zugleich vermitteln – dafür ist die Blogging-Story das perfekte Mittel. Unsere Content-Profis testen Ihr Produkt und berichten in einem authentischen, emotional verfassten Erlebnisbericht über ihre Erfahrungen. Diese glaubwürdigen und transparenten Inhalte wirken positiv auf die Kaufentscheidung potenzieller Kunden.
              </p>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>Leistungen &amp; Tarif</h2>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="margin-t-s"></div>
              <h2>BASISMODULE</h2>
              <ul class="content-ul">
                <li><strong>Erstellung der Basisstory</strong><br />Content-Profis der Kleinen Zeitung erstellen und gestalten mit Ihnen eine ansprechende Story für genau Ihre Zielgruppe und ermöglichen einen exakten Überblick über Ihre Storyviews.</li>
                <li><strong>Infobox / Disclaimerbox / Outlink</strong><br />Mithilfe einer Infobox / Disclaimerbox / Outlink werden potenzielle Kunden bestmöglich informiert und direkt auf Ihre Website weitergeleitet.</li>
                <li>
                  <strong>Fotoserie</strong><br />
                  Ein Content-Profi der Kleinen Zeitung tauscht sich vor Ort mit Ihnen über Ihr Produkt aus und erstellt eine ausdrucksstarke Fotoserie.
                </li>
                <li>
                  <strong>Bildergalerie</strong><br />
                  Bleiben Sie langfristig in Erinnerung – die Bildergalerie bietet Ihren Kunden einen visuellen Überblick über Ihre Dienstleistungen oder Produkte.
                </li>
                <li>
                  <strong>Social-Share-Buttons</strong><br />
                  Das Teilen Ihrer Story mit Freunden und Familie ist unkompliziert mithilfe diverser Social-Share-Buttons möglich.
                </li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 garantierte Leserinnen und Leser</div>
                  <div class="col-lg-6 col-4 text-align-right">5.460,00</div>
                </div>
              </div>
              <img src="@/assets/img/img-content/digital/Blogging-Story_iMac.png" alt="Blogging Story" class="img-fluid  img-center" />
              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/advertorials/sponsored/6121354" target="_blank">Siehe Beispiel </a>
              </div>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>ADD-ON-MODULE</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Social-Media-Beitrag-Embed</strong><br />Fügen Sie Ihre Social-Media-Beiträge in Ihrer Blogging-Story ein, um einen starken Onlineauftritt zu präsentieren.</li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Social-Media-Beitrag-Embed</div>
                  <div class="col-lg-6 col-4 text-align-right">55,00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Videoproduktion</strong><br />Wecken Sie Interesse durch ein ausdrucksstarkes Produktvideo, welches wir für Sie produzieren.</li>
              </ul>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Videoproduktion</div>
                  <div class="col-lg-6 col-4 text-align-right">1.950,00</div>
                </div>
              </div>
            </div>
          </div>

          <!-- -->
          <div class="download-pdf">
            <div class="line"><span>DOWNLOAD</span></div>
            <a href="../../pdf/digital/Kleine-Zeitung-Blogging-Story.pdf" target="_blank">
              <div class="row">
                <div class="col-8 justify-content-center align-self-center">
                  Kleine Zeitung - Blogging Story
                </div>
                <div class="col-4 justify-content-end align-self-center text-right">
                  <span>224 kb // PDF</span>
                </div>
              </div>
            </a>
          </div>
          <!---->
          <div class="info-txt-preis margin-b-s">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab4" :title="'Interactive-Story'">
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Interactive-Story</h2>
              <h3>Interaktive Themenformat für konkrete Lösungen durch Ihre Produkte und Leistungen</h3>
            </div>
            <div class="col-lg-12 align-self-center">
              <p>
                Bieten Sie genau Ihrer Zielgruppe konkrete Lösungen durch Ihre Produkte und Leistungen an. Lassen Sie Leserinnen und Leser mithilfe von Quiz und Abstimmungstool mit Ihnen interagieren. Zeigen Sie auf, welchen Mehrwert Sie Ihren Kunden bieten und warum Ihr Produkt und Ihre Leistungen die optimale Problemlösung sind. Ob Reportagen, Fragestellungen, Expertentipps – unsere
                Content-Profis beraten Sie, welches der unterschiedlichen Erzählformate am besten zu Ihnen passt.
              </p>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>Leistungen &amp; Tarif</h2>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="margin-t-s"></div>
              <h2>BASISMODULE</h2>

              <ul class="content-ul">
                <li>
                  <strong>Erstellung der Basisstory</strong><br />
                  Content-Profis der Kleinen Zeitung erstellen und gestalten mit Ihnen eine ansprechende Story für genau Ihre Zielgruppe und ermöglichen einen exakten Überblick über Ihre Storyviews.
                </li>
                <li>
                  <strong>Infobox / Disclaimerbox / Outlink</strong><br />
                  Mithilfe einer Infobox / Disclaimerbox / Outlink werden potenzielle Kunden bestmöglich informiert und direkt auf Ihre Website weitergeleitet.
                </li>
                <li>
                  <strong>Quiz</strong><br />
                  Interaktion mit Kunden fördern – mit einem spannenden Quiz animieren Sie unsere Leserinnen und Leser, sich intensiv mit der Story auseinanderzusetzen.
                </li>
                <li>
                  <strong>Abstimmungstool</strong><br />
                  Mit einem Abstimmungstool sprechen Sie Ihre Kunden direkt an, lassen sie am Thema teilhaben und stärken somit die Kundenbindung zu Ihrem Unternehmen.
                </li>
                <li>
                  <strong>Bildergalerie</strong><br />
                  Bleiben Sie langfristig in Erinnerung – die Bildergalerie bietet Ihren Kunden einen visuellen Überblick über Ihre Dienstleistungen oder Produkte.
                </li>
                <li>
                  <strong>Social-Share-Buttons </strong><br />
                  Das Teilen Ihrer Story mit Freunden und Familie ist unkompliziert mithilfe diverser Social-Share-Buttons möglich.
                </li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 garantierte Leserinnen und Leser</div>
                  <div class="col-lg-6 col-4 text-align-right">5.330,00</div>
                </div>
              </div>
              <img src="@/assets/img/img-content/digital/Interactive-Story_iMac.png" alt="Interactiv Story" class="img-fluid  img-center" />
              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/advertorials/sponsored/6116653" target="_blank">Siehe Beispiel </a>
              </div>
            </div>
          </div>

          <div class="row margin-t-s">
            <div class="col-lg-12">
              <h2>ADD-ON-MODULE</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Checkliste als Download</strong><br />Stellen Sie unseren Leserinnen und Leser eine strukturierte Checkliste als Download als effizientes Hilfsmittel zur Verfügung.</li>
              </ul>
              <br />
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Checkliste als Download</div>
                  <div class="col-lg-6 col-4 text-align-right">55,00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Preisgalerie</strong><br />Eine klar strukturierte und übersichtliche Preisgalerie hilft potenziellen Kunden, sich über Ihre Leistungen zu informieren.</li>
              </ul>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Preisgalerie</div>
                  <div class="col-lg-6 col-4 text-align-right">390,00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li><strong>Infografik (verfügbar ab Q3/2022)</strong><br />Infografiken sind eine großartige Möglichkeit, Geschichten zu erzählen und für ­jedermann schnell und leicht verständlich.</li>
              </ul>
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Infografik (verfügbar ab Q3/2022)</div>
                  <div class="col-lg-6 col-4 text-align-right">auf Anfrage</div>
                </div>
              </div>
            </div>
          </div>

          <!-- -->
          <div class="download-pdf">
            <div class="line"><span>DOWNLOAD</span></div>
            <a href="../../pdf/digital/Kleine-Zeitung-Interactive-Story.pdf" target="_blank">
              <div class="row">
                <div class="col-8 justify-content-center align-self-center">
                  Kleine Zeitung - Interactive Story
                </div>
                <div class="col-4 justify-content-end align-self-center text-right">
                  <span>370 kb // PDF</span>
                </div>
              </div>
            </a>
          </div>
          <!---->
          <div class="info-txt-preis margin-b-s">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab5" :title="'Advertorial'">
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Advertorial</h2>
            </div>
            <div class="col-lg-12">
              <p>Ein Advertorial unterscheidet sich kaum von einem redaktionellen Artikel und dient der gezielten Bewerbung einer Sache (Unternehmen, Angebot, Produkt). Auf diese Weise wird die Werbung nicht als störend empfunden. Damit Ihr Advertorial auch von der gewünschten Zielgruppe gefunden und gelesen wird, sorgen wir für reichlich Aufmerksamkeit.</p>
            </div>
            <div class="col-lg-6 align-self-center">
              <p>
                Das Native Ad und das Perfomance-Upgrade verlinken bei Klick auf den Artikel, welcher vom Kunden selbst angeliefert wird (kurzer PR-Text). Das Advertorial dient als Landing Page. Umsetzung mit Bildergalerien, Videos und Factboxes sind möglich. Die werblichen Inhalte fügen sich optimal in die Inhalte der Website oder App ein, ohne den Lesefluss des Users zu unterbrechen. All das
                führt zu einer höheren Akzeptanz beim User.
              </p>
            </div>
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li>Hohe Nutzerakzeptanz</li>
                <li>Positive Markenwahrnehmung</li>
                <li>Nachhaltiger Branding-Effekt (Kundenbindung)</li>
              </ul>
            </div>
          </div>
          <!--<div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>Unternehmen/Produkt steht im Vordergrund</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>maßgeschneiderter<span class="breaker"></span> PR-Text</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>auffällig und reichweitenstark</h5>
            </div>
          </div>-->
          <div class="row">
            <div class="col-lg-6">
              <br />
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Content-Produktion inkl. Bildersuche</li>
                <li>Korrektorat (Zwei Korrekturschleifen)</li>
                <li>Anlage des Artikels auf kleinezeitung.at</li>
                <li>Vorlaufzeit 5 Werktage</li>
              </ul>

              <!--<p><small>Damit Ihr Text bei der gewünschten Zielgruppe ankommt, empfehlen wir einen Content Teaser mit mind. 10.000 Sichtkontakten.</small></p>-->
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">* Produktion</div>
                  <div class="col-lg-6 col-4 text-align-right">290,00</div>
                </div>
                <!--<div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Mindestbuchungsvolumen</div>
                  <div class="col-lg-6 col-4 text-align-right">500,00*</div>
                </div>
                <div class="info-txt-tabelle">Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500 Euro beträgt der TKP maximal 19,50 und reduziert sich bei höherem Kampagnenbudget entsprechend.</div>-->
                <div class="info-txt-tabelle">* Produktionskosten sind nicht rabattfähig</div>
                <div class="info-txt-tabelle">
                  Nur in Kombination mit einem Native Ad (Mindesbuchungsvolumen: 500,00 EURO) oder Performance Upgrade buchbar
                </div>
              </div>

              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/Devices-Advertorial.png" alt="Advertorial" class="img-fluid" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab6" :title="'Performance Upgrade '">
        <h2>Leistung &amp; Tarif</h2>
        <div class="row">
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">je 1.000 Klicks</div>
                <div class="col-lg-6 col-4 text-align-right">2.800,00</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab7" :title="'Themenspecial'">
        <div class="inner-content">
          <div class="row">
            <div class="col-lg-6">
              <br />
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Max. 10 Artikel</li>
                <li>Mindesbuchung: 6 Artikel</li>
                <li>Content-Produktion, Bildersuche, Korrektorat und Anlage der Artikel</li>
                <li>Einrichtung der Übersichtsseite sowie eigenen Subdomain auf kleinezeitung.at*</li>
                <li>Vorlaufzeit: 10 Werktage</li>
              </ul>
              <!--<p><small>Damit Ihr Text bei der gewünschten Zielgruppe ankommt, empfehlen wir einen Content Teaser mit mind. 10.000 Sichtkontakten.</small></p>-->
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Pro Artikel</div>
                  <div class="col-lg-6 col-4 text-align-right">290,00</div>
                </div>
                <div class="info-txt-tabelle">* Produktionskosten sind nicht rabattfähig</div>
                <div class="info-txt-tabelle">Nur in Kombination mit einem Native Ad buchbar (Mindestbuchungsvolumen: 500,00 Euro)</div>
              </div>

              <!--<div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>-->
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/Devices-Themenspecial-1.png" alt="Themenspecial" class="img-fluid img-s-75 img-center" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>
    </TabGroup>
    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/prprodukte/DIGITALE-PR-Produkte-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif PR-Produkte Digital
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>799 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import HighlightBox from '@/components/HighlightBox.vue';

export default defineComponent({
  components: {
    TabGroup,
    Tab,
    HighlightBox,
  },
});
</script>
